import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import About from '../about'

const AboutEs = ({ data, ...props }) => {
  return (
    <About {...props} data={{ ...data, allAboutJson: data.allAboutEsJson }} />
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        banner: file(relativePath: { eq: "banner-about.png" }) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
        quoteSectionImage: allFile(
          filter: { name: { regex: "/^aboutQuote*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fluid(maxWidth: 1200, quality: 100) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        screenshots: allFile(
          filter: { name: { regex: "/^aboutScreenshot*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fluid(maxWidth: 400) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        teamMembers: allFile(
          filter: { name: { regex: "/^teammember*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fixed(width: 300) {
                  ...GatsbyImageSharpFixed
                  height
                  width
                }
              }
            }
          }
        }
        firstImageSection: allFile(
          filter: { name: { regex: "/^featuremember*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fluid(maxWidth: 1200, quality: 100) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        allAboutEsJson {
          edges {
            node {
              bannerHeadline
              heroHeading
              heroSubheading
              introSection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonLink
                buttonLabel
                buttonColor
                buttonFocus
                triangle
                bg
                textAlign
              }
              quoteSection {
                containerColor
                smallText
                smallTextColor
                largeText
                largeTextColor
                flip
                textAlign
                button
                storeButton
                buttonLink
                buttonLabel
                buttonBg
                buttonFocus
                backgroundAlt
              }
              secondarySection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonLink
                buttonLabel
                buttonColor
                buttonFocus
                triangle
                bg
                textAlign
              }
              thirdSection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonLink
                buttonLabel
                buttonColor
                buttonFocus
                triangle
                bg
                textAlign
              }
              lastSection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonLink
                buttonLabel
                buttonColor
                buttonFocus
                triangle
                bg
                order
                textAlign
                imageAlt
                imageExists
              }
              valuesTitle
              valuesIconColor
              values {
                icon
                title
                value
              }
              imageSection1 {
                containerColor
                urlDescription
                flip
                reverse
                button
                storeButton
                buttonLabel
                buttonColor
                buttonLink
                focus
                src
                box
                srcDescription
                titleColor
                title
                firstBody
                secondBody
                thirdBody
              }
              imageSection2 {
                containerColor
                urlDescription
                flip
                reverse
                button
                storeButton
                buttonLabel
                buttonColor
                buttonLink
                focus
                src
                box
                srcDescription
                titleColor
                title
                firstBody
                secondBody
                thirdBody
              }
              team {
                name
                position
                imageAlt
              }
              pageTitle
              socialTitle
              pageDescription
              socialDescription
              socialImage
            }
          }
        }
      }
    `}
    render={data => <AboutEs data={data} {...props} />}
  />
)
